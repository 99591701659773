import React from "react";
import { Container } from "reactstrap";
import ticketsStyles from "../../styles/mainpage/tickets.module.css";

var Tickets = function Tickets() {
  var stats = [{
    type: "dias",
    count: "03"
  }, {
    type: "palestras",
    count: "13"
  }, {
    type: "workshops",
    count: "03"
  }, {
    type: "visitas",
    count: "02"
  }];
  return React.createElement("section", {
    id: "tickets",
    className: ticketsStyles.section
  }, React.createElement(Container, null, React.createElement("ul", {
    className: ticketsStyles.wrapper
  }, stats.map(function (_ref) {
    var type = _ref.type,
        count = _ref.count;
    return React.createElement("li", {
      key: type
    }, React.createElement("span", null, count), React.createElement("span", null, type));
  })), React.createElement("a", {
    className: ticketsStyles.lead,
    href: "https://www.eventbrite.com/e/semana-de-informatica-2019-tickets-74550752307",
    target: "_blank",
    rel: "noopener noreferrer"
  }, "Inscreve-te!")));
};

export default Tickets;