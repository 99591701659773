import "core-js/modules/es6.function.name";
import React from "react";
import { Col } from "reactstrap";
import { Link } from "gatsby";
import Occupations from "./occupations";
import Overlay from "../utils/overlay";
import ParticipantsStyle from "../../styles/utils/participants-display.module.css";
import SpeakerStyle from "../../styles/speakers/single-speaker.module.css";

var SingleSpeaker = function SingleSpeaker(_ref) {
  var speaker = _ref.speaker,
      color = _ref.color;
  return React.createElement(Col, {
    className: ParticipantsStyle.member_container + " mb-3",
    key: speaker.name
  }, React.createElement(Overlay, {
    image: speaker.img.childImageSharp.fluid,
    color: color,
    linkedin: speaker.linkedin,
    twitter: speaker.twitter,
    github: speaker.github,
    website: speaker.website
  }), React.createElement("div", {
    className: "mt-2"
  }, React.createElement(Link, {
    to: speaker.path,
    className: SpeakerStyle.name
  }, speaker.name)), React.createElement(Occupations, {
    occupations: speaker.occupations
  }));
};

export default SingleSpeaker;