import React from "react";
import SpeakerNameStyles from "../../styles/talk/speaker_name.module.css";

var Occupations = function Occupations(_ref) {
  var occupations = _ref.occupations;
  return React.createElement("div", {
    className: SpeakerNameStyles.occupation
  }, occupations.map(function (occupation, index) {
    return React.createElement("span", {
      key: occupation.what
    }, occupation.what, " @ ", occupation.where, index === occupations.length - 1 ? "" : ", ");
  }));
};

export default Occupations;