import React from "react";
import OccupationsStyles from "../../styles/utils/occupations.module.css";

var Occupations = function Occupations(_ref) {
  var occupations = _ref.occupations;
  return React.createElement("div", null, occupations.map(function (occupation, index) {
    return React.createElement("span", {
      key: index,
      className: OccupationsStyles.occupations
    }, " @ ", occupation.where, index === occupations.length - 1 ? "" : ", ");
  }));
};

export default Occupations;