import React from "react";
import SEO from "../components/common/seo";
import SoonStyles from "../styles/soon.module.css";
import Brevemente from "../components/brevemente/brevemente";
import Info from "../components/brevemente/info";
import { Container, Row, Col } from "reactstrap";
import Icon from "../images/svg/logo_sinf_comp.inline.svg";

var IndexPage = function IndexPage() {
  return React.createElement("div", {
    className: SoonStyles.background
  }, React.createElement(SEO, {
    title: "Coming Soon"
  }), React.createElement(Brevemente, null), React.createElement(Container, {
    fluid: true,
    className: SoonStyles.content_container
  }, React.createElement(Row, {
    className: SoonStyles.top_row
  }, React.createElement(Col, null, React.createElement(Icon, {
    alt: "Logo",
    className: SoonStyles.logo
  }))), React.createElement(Row, {
    className: SoonStyles.bottom_row
  }, React.createElement(Col, null, React.createElement(Info, null)))));
};

export default IndexPage;