import "core-js/modules/es6.function.name";
import React from "react";
import Overlay from "../utils/overlay";

var Member = function Member(_ref) {
  var data = _ref.data,
      color = _ref.color;
  return React.createElement(Overlay, {
    image: data.img.childImageSharp.fluid,
    color: color,
    linkedin: data.linkedin,
    github: data.github,
    website: data.website
  }, React.createElement("div", null, data.name));
};

export default Member;