import React from "react";
import eventsStyles from "../../styles/program/events.module.css";

var TimePlace = function TimePlace(_ref) {
  var start_time = _ref.start_time,
      end_time = _ref.end_time,
      place = _ref.place;
  return React.createElement("p", {
    className: eventsStyles.timePlace
  }, start_time, " ", end_time && React.createElement("span", null, "- ", end_time), " ", place && React.createElement("span", null, "| ", place));
};

export default TimePlace;