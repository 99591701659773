import React from "react";
import { Container, Row, Col } from "reactstrap";
import Prize from "../competition/prize";
import Participate from "../utils/participate";
import CompetitionStyles from "../../styles/competition/competition.module.css";

var Competition = function Competition() {
  return React.createElement("div", null, React.createElement(Container, null, React.createElement(Row, null, React.createElement(Col, {
    xs: "12",
    className: CompetitionStyles.competitionText + " my-4 py-5"
  }, "Participa na nossa Competi\xE7\xE3o de Programa\xE7\xE3o e habilita-te a ganhar 3 fant\xE1sticos pr\xE9mios! Fica atento aos 6 desafios, de crescente dificuldade, que vamos lan\xE7ar para seres o mais r\xE1pido. No entanto, a rapidez n\xE3o \xE9 tudo pelo que tens de ser tamb\xE9m o melhor a resolver os desafios.", React.createElement("br", null), React.createElement("br", null), "N\xE3o percas a Sess\xE3o de Encerramento, onde ser\xE3o anunciados os vencedores e ser\xE1 feita a entrega de pr\xE9mios.", React.createElement("br", null), React.createElement("br", null), "A participa\xE7\xE3o \xE9 volunt\xE1ria e individual e aberta a todos os participantes da Semana de Inform\xE1tica."))), React.createElement("div", {
    className: CompetitionStyles.conditions + " pt-4"
  }, React.createElement(Container, null, React.createElement(Row, null, React.createElement(Col, {
    className: CompetitionStyles.conditionsText
  }, "Condi\xE7\xF5es", React.createElement("hr", {
    align: "left",
    className: CompetitionStyles.horizontalRuler
  }))), React.createElement(Row, null, React.createElement(Col, {
    xs: "12",
    className: CompetitionStyles.conditionList
  }, React.createElement("ul", null, React.createElement("li", null, "A Competi\xE7\xE3o ter\xE1 in\xEDcio dia 27 de Outubro \xE0s 15h00, decorrendo durante os 3 pr\xF3ximos dias at\xE9 dia 30 de Outubro \xE0s 17:00."), React.createElement("li", null, "A competi\xE7\xE3o decorrerr\xE1 online, atrav\xE9s da plataforma HackerRank."), React.createElement("li", null, "Ser\xE3o lan\xE7ados diariamente 2 novos desafios: dia 27, \xE0s 15h00 e dias 28 e 29, \xE0s 21h00."), React.createElement("li", null, "Apenas estar\xE1s habilitado aos pr\xE9mios caso tenhas tenhas realizado ", React.createElement("strong", null, "inscri\xE7\xE3o "), "e ", React.createElement("strong", null, "check-in"), " na SINF."), React.createElement("li", null, "A entrega de pr\xE9mios ser\xE1 realizada durante a Sess\xE3o de Encerramento."), React.createElement("li", null, "A c\xF3pia de c\xF3digo \xE9 estritamente proibida, sendo esta monitorizada pela pr\xF3pria plataforma HackerRank."), React.createElement("li", null, "A pontua\xE7\xE3o em cada exerc\xEDcio ser\xE1 influenciada pela qualidade da solu\xE7\xE3o, sendo o factor de desempate o tempo demorado a submeter a resolu\xE7\xE3o."), React.createElement("li", null, "Caso sejas um dos vencedores, certifica-te que est\xE1s presente na Sess\xE3o de Encerramento ou indica-nos o contacto de algu\xE9m que possa receber o pr\xE9mio por ti.")))))), React.createElement(Container, {
    className: CompetitionStyles.whiteSection
  }, React.createElement(Row, {
    className: CompetitionStyles.prizeSquares
  }, React.createElement(Prize, {
    type: "first",
    title: "Trotinete el\xE9trica",
    description: "STOREX RIDE 80XL PRETA"
  }), React.createElement(Prize, {
    type: "second",
    title: "Headphones Bluetooth",
    description: "JBL Live 650BTNC"
  }), React.createElement(Prize, {
    type: "third",
    title: "Cart\xE3o oferta Fnac 100\u20AC",
    description: ""
  }))), React.createElement(Participate, {
    link: "https://www.hackerrank.com/sinf-2019"
  }));
};

export default Competition;