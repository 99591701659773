import React from "react";
import TimeStyles from "../../styles/talk/time_place.module.css";

var TimePlace = function TimePlace(_ref) {
  var time = _ref.time,
      place = _ref.place;
  return React.createElement("div", {
    className: TimeStyles.container
  }, time, " ", " • ", " ", place);
};

export default TimePlace;