import React from "react";
import { Container } from "reactstrap";
import Layout from "../components/common/layout";
import SEO from "../components/common/seo";
import PageBanner from "../components/utils/page_banner";
import DailySpeakers from "../components/speakers/daily-speakers";
import Icon from "../images/svg/speakers.inline.svg";

var SpeakersPage = function SpeakersPage(_ref) {
  var data = _ref.data;
  return React.createElement(Layout, null, React.createElement(SEO, {
    title: "Speakers"
  }), React.createElement(PageBanner, null, React.createElement(Icon, null)), React.createElement(Container, null, data.allMarkdownRemark.group.map(function (_ref2, index) {
    var edges = _ref2.edges;
    return React.createElement(DailySpeakers, {
      talks: edges,
      key: index,
      day: index + 1
    });
  })));
};

export var pageQuery = "3895831872";
export default SpeakersPage;