import React from "react";
import { Container, Row, Col } from "reactstrap";
import SoonStyles from "../../styles/soon.module.css";
import { FaLinkedin, FaFacebookSquare, FaEnvelope } from "react-icons/fa";
var links = [{
  icon: React.createElement(FaLinkedin, null),
  href: "https://www.linkedin.com/company/sinffeup/"
}, {
  icon: React.createElement(FaFacebookSquare, null),
  href: "https://facebook.com/sinfFEUP"
}, {
  icon: React.createElement(FaEnvelope, null),
  href: "mailto:geral@sinf.pt"
}];

var Info = function Info() {
  return React.createElement(Container, {
    fluid: true
  }, React.createElement(Row, {
    className: SoonStyles.info
  }, React.createElement(Col, {
    xs: "12",
    md: "5"
  }, React.createElement("div", {
    className: SoonStyles.text_info
  }, "O site est\xE1 em remodela\xE7\xE3o.", React.createElement("br", null), "Pedimos desculpa pelo inc\xF3modo!")), React.createElement(Col, {
    xs: "12",
    md: "5"
  }, React.createElement("div", {
    className: SoonStyles.text_info + " " + SoonStyles.left_text
  }, React.createElement("div", {
    className: SoonStyles.icon_links
  }, links.map(function (_ref, index) {
    var icon = _ref.icon,
        href = _ref.href;
    return React.createElement("a", {
      href: href,
      className: SoonStyles.icon_link,
      key: index
    }, icon);
  })), React.createElement("p", null, "28 a 30 outubro | FEUP")))));
};

export default Info;