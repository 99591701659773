import React from "react";
import { FaAngleUp } from "react-icons/fa";
import eventsStyles from "../../styles/program/events.module.css";

var DescriptionToggler = function DescriptionToggler(_ref) {
  var id = _ref.id,
      showAll = _ref.showAll,
      setShowAll = _ref.setShowAll,
      backgroundColor = _ref.backgroundColor;
  return React.createElement("div", {
    className: eventsStyles.toggler
  }, React.createElement("input", {
    type: "checkbox",
    checked: showAll,
    onChange: function onChange(e) {
      return setShowAll(e.target.checked);
    },
    id: id
  }), React.createElement("label", {
    htmlFor: id,
    style: {
      backgroundColor: backgroundColor
    }
  }, React.createElement(FaAngleUp, {
    color: "white",
    size: "1.5rem"
  })));
};

export default DescriptionToggler;