import "core-js/modules/es6.function.name";
import React from "react";
import { Link } from "gatsby";
import Img from "gatsby-image";
import speakersStyles from "../../../styles/mainpage/speakers.module.css";

var SingleSpeaker = function SingleSpeaker(_ref) {
  var speaker = _ref.speaker,
      path = _ref.path;
  return React.createElement("div", {
    className: speakersStyles.speaker
  }, React.createElement(Img, {
    fluid: speaker.img.childImageSharp.fluid,
    alt: speaker.name,
    className: speakersStyles.image
  }), React.createElement(Link, {
    to: path
  }, "Ver palestra"));
};

export default SingleSpeaker;