import React from "react";
import { Container, Row, Col } from "reactstrap";
import Countdown from "./countdown";
import AboutStyles from "../../../styles/mainpage/about.module.css";

var About = function About() {
  return React.createElement("div", {
    className: AboutStyles.aboutContainer + " pt-4",
    id: "sobre-nos"
  }, React.createElement(Container, null, React.createElement(Row, {
    className: "pt-5 pb-4 mx-2 mx-sm-0"
  }, React.createElement(Col, {
    xs: "12",
    md: "6"
  }, React.createElement("h3", {
    className: AboutStyles.title
  }, "Semana de Inform\xE1tica"), React.createElement("p", null, "A Semana de Inform\xE1tica (SINF), organizada pelo N\xFAcleo de Inform\xE1tica da Associa\xE7\xE3o de Estudantes da Faculdade de Engenharia da Universidade do Porto (", React.createElement("a", {
    href: "https://ni.fe.up.pt/",
    rel: "noopener noreferrer",
    target: "_blank"
  }, "NIAEFEUP"), "), foi criada com o intuito de permitir aos estudantes, independentemente do curso, desenvolver as suas capacidades nas diversas \xE1reas da Inform\xE1tica, promovendo a sua intera\xE7\xE3o com o mundo empresarial atrav\xE9s de eventos sociais.")), React.createElement(Col, {
    xs: "12",
    md: "6",
    className: "mt-5 mt-md-0"
  }, React.createElement("h3", {
    className: AboutStyles.title
  }, "SINF'2019"), React.createElement("p", null, "A SINF\u20192019 decorre entre os dias 28 e 30 de Outubro, na Faculdade de Engenharia da Universidade do Porto. Os participantes t\xEAm a seu dispor palestras, workshops e visitas a empresas, onde entrar\xE3o em contacto com diversas tecnologias e conceitos que n\xE3o interv\xEAm no percurso acad\xE9mico, focando-se portanto nas skills t\xE9cnicas, assim como sess\xF5es de networking e pitch, fomentando o seu contacto com empresas de topo a n\xEDvel nacional e internacional, podendo desenvolver as suas soft skills."))), React.createElement(Row, {
    className: "justify-content-center"
  }, React.createElement(Col, {
    xs: "11",
    sm: "10",
    md: "8",
    className: AboutStyles.countdownContainer
  }, React.createElement(Countdown, null)))));
};

export default About;