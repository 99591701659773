import "core-js/modules/es6.string.link";
import "core-js/modules/es6.function.name";
import React from "react";
import Img from "gatsby-image";
import { Container, Col, Row } from "reactstrap";
import { FaFacebookSquare, FaInstagram } from "react-icons/fa";
import WebIcon from "../../images/svg/web_icon.inline.svg";
import FrameStyles from "../../styles/talk/frame.module.css";
import PartnershipStyles from "../../styles/talk/partnership.module.css";

var Partnership = function Partnership(_ref) {
  var partnership = _ref.partnership;
  return React.createElement(Container, {
    className: PartnershipStyles.container
  }, React.createElement(Row, null, React.createElement(Col, {
    xs: "12",
    className: PartnershipStyles.titleContainer
  }, React.createElement("h1", null, "Parceria", React.createElement("hr", {
    className: PartnershipStyles.underline
  })))), React.createElement(Row, null, React.createElement(Col, {
    xs: "12",
    lg: "5",
    xl: "4",
    className: PartnershipStyles.imageContainer
  }, React.createElement("div", {
    className: FrameStyles.image + " " + PartnershipStyles.partnerImage
  }, React.createElement(Img, {
    fluid: partnership.img.childImageSharp.fluid
  }))), React.createElement(Col, {
    className: PartnershipStyles.infoContainer
  }, React.createElement("h2", {
    className: PartnershipStyles.name
  }, partnership.name), React.createElement("div", {
    className: PartnershipStyles.socials
  }, partnership.facebook ? React.createElement("a", {
    href: partnership.facebook,
    className: FrameStyles.link
  }, React.createElement(FaFacebookSquare, null)) : "", " ", partnership.instagram ? React.createElement("a", {
    href: partnership.instagram,
    className: FrameStyles.link
  }, React.createElement(FaInstagram, null)) : "", " ", partnership.website ? React.createElement("a", {
    href: partnership.website,
    className: FrameStyles.link
  }, React.createElement(WebIcon, null)) : ""), React.createElement("div", {
    className: PartnershipStyles.description
  }, partnership.description))));
};

export default Partnership;