import React, { useState } from "react";
import { Container, TabContent, TabPane } from "reactstrap";
import Layout from "../components/common/layout";
import SEO from "../components/common/seo";
import ProgramNav from "../components/program/ProgramNav";
import DailySchedule from "../components/program/DailySchedule";
import PageBanner from "../components/utils/page_banner";
import PageHeader from "../images/svg/programa.inline.svg";
import programStyles from "../styles/program/program.module.css";
import { splitDays } from "../utils/programUtils";
export var eventsQuery = "2178334951";

var ProgramPage = function ProgramPage(_ref) {
  var data = _ref.data;

  var _useState = useState(data.allThemesJson.edges[0].node),
      activeTab = _useState[0],
      setActiveTab = _useState[1];

  var days = splitDays(data);

  var toggle = function toggle(tab) {
    if (activeTab !== tab) setActiveTab(tab);
  };

  return React.createElement(Layout, null, React.createElement(SEO, {
    title: "Program"
  }), React.createElement(PageBanner, null, React.createElement(PageHeader, null)), React.createElement(Container, {
    className: programStyles.container
  }, React.createElement(ProgramNav, {
    days: data.allThemesJson.edges,
    activeTab: activeTab,
    toggle: toggle
  }), React.createElement(TabContent, {
    activeTab: activeTab.date
  }, days.map(function (day) {
    return React.createElement(TabPane, {
      key: day[0].node.frontmatter.day,
      tabId: day[0].node.frontmatter.day
    }, React.createElement(DailySchedule, {
      events: day
    }));
  }))));
};

export default ProgramPage;