import "core-js/modules/es6.function.name";
import "core-js/modules/es7.symbol.async-iterator";
import "core-js/modules/es6.symbol";
import "core-js/modules/web.dom.iterable";
import React from "react";
import { Container } from "reactstrap";
import SEO from "../components/common/seo";
import Layout from "../components/common/layout";
import Team from "../components/team/team";
import PageBanner from "../components/utils/page_banner";
import TeamStyles from "../styles/team/team.module.css";
import Icon from "../images/svg/equipa.inline.svg";

var determine_first = function determine_first(teams, n_per_row) {
  var odd_row = [true];
  var rows = 0;

  for (var _iterator = teams, _isArray = Array.isArray(_iterator), _i = 0, _iterator = _isArray ? _iterator : _iterator[Symbol.iterator]();;) {
    var _ref;

    if (_isArray) {
      if (_i >= _iterator.length) break;
      _ref = _iterator[_i++];
    } else {
      _i = _iterator.next();
      if (_i.done) break;
      _ref = _i.value;
    }

    var team = _ref;
    var element = team.members;
    rows += Math.ceil(element.length / n_per_row);

    if (rows % 2 === 0) {
      odd_row.push(true);
    } else {
      odd_row.push(false);
    }
  }

  return odd_row;
};

var TeamPage = function TeamPage(_ref2) {
  var data = _ref2.data;
  var teams = data.allTeamJson.nodes;
  var n_per_row = 4;
  var is_starting_row_odd = determine_first(teams, n_per_row);
  return React.createElement(Layout, null, React.createElement(SEO, {
    title: "Equipa"
  }), React.createElement(PageBanner, null, React.createElement(Icon, null)), React.createElement(Container, {
    className: TeamStyles.container
  }, teams.map(function (team, index) {
    return React.createElement(Team, {
      name: team.name,
      members: team.members,
      key: index,
      n_per_row: n_per_row,
      start_odd: is_starting_row_odd[index]
    });
  })));
};

export var pageQuery = "3830257030";
export default TeamPage;