import "core-js/modules/es6.function.name";
import "core-js/modules/es6.object.assign";
import staticQueryData from "../../../../public/static/d/406703284.json";
import React from "react";
import { Link } from "gatsby";
import speakersStyles from "../../../styles/mainpage/speakers.module.css";
import Carousel from "../../utils/carousel";
import SingleSpeaker from "./SingleSpeaker";

var getAllSpeakers = function getAllSpeakers(data) {
  var speakers = [];
  data.allMarkdownRemark.edges.forEach(function (_ref) {
    var node = _ref.node;
    node.frontmatter.type !== "Placeholder" && speakers.push.apply(speakers, node.frontmatter.speakers.map(function (speaker) {
      return Object.assign({}, speaker, {
        path: node.frontmatter.path,
        id: node.id
      });
    }));
  });
  return speakers;
};

var Speakers = function Speakers() {
  var data = staticQueryData.data;
  var speakers = getAllSpeakers(data);
  return speakers.length > 0 && React.createElement("section", {
    id: "speakers",
    className: speakersStyles.speakersSection
  }, React.createElement("h2", {
    className: speakersStyles.h2
  }, "Speakers", React.createElement("hr", {
    className: speakersStyles.headingLine
  })), React.createElement(Carousel, {
    numMobileItems: 1,
    numTabletItems: 2,
    numDesktopItems: 4
  }, speakers.map(function (speaker) {
    return React.createElement(SingleSpeaker, {
      key: speaker.name + "-" + speaker.id,
      speaker: speaker,
      path: speaker.path
    });
  })), React.createElement(Link, {
    className: speakersStyles.allLink,
    to: "/speakers"
  }, "Ver todos os speakers"));
};

export default Speakers;