import "core-js/modules/es7.array.includes";
import "core-js/modules/es6.string.includes";
import React, { useState } from "react";
import { TabContent, TabPane } from "reactstrap";
import SimpleEvent from "./SimpleEvent";
import CommonEvent from "./CommonEvent";
import EventsNav from "./EventsNav";
import programStyles from "../../styles/program/program.module.css";

var DailySchedule = function DailySchedule(_ref) {
  var events = _ref.events;

  var _useState = useState("talks"),
      activeTab = _useState[0],
      setActiveTab = _useState[1];

  var toggle = function toggle(tab) {
    if (activeTab !== tab) setActiveTab(tab);
  };

  return React.createElement(React.Fragment, null, React.createElement(EventsNav, {
    toggle: toggle,
    activeTab: activeTab
  }), React.createElement(TabContent, {
    activeTab: activeTab
  }, React.createElement(TabPane, {
    tabId: "talks"
  }, React.createElement("div", {
    className: programStyles.schedule
  }, events.filter(function (event) {
    return event.node.fileAbsolutePath.includes("default") || event.node.fileAbsolutePath.includes("talks");
  }).map(function (event) {
    return event.node.fileAbsolutePath.includes("default") ? React.createElement(SimpleEvent, {
      key: event.node.id,
      event: event.node.frontmatter
    }) : React.createElement(CommonEvent, {
      key: event.node.id,
      event: event.node,
      color: "#2da6b0"
    });
  }))), React.createElement(TabPane, {
    tabId: "activities"
  }, React.createElement("div", {
    className: programStyles.schedule
  }, events.filter(function (event) {
    return !event.node.fileAbsolutePath.includes("talks");
  }).map(function (event) {
    var path = event.node.fileAbsolutePath;
    var toRender;

    if (path.includes("default")) {
      toRender = React.createElement(SimpleEvent, {
        key: event.node.id,
        event: event.node.frontmatter
      });
    } else if (path.includes("sessions")) {
      toRender = React.createElement(CommonEvent, {
        key: event.node.frontmatter.start_time,
        event: event.node,
        color: "#0c0044"
      });
    } else if (path.includes("workshops")) {
      toRender = React.createElement(CommonEvent, {
        key: event.node.frontmatter.start_time,
        event: event.node,
        color: "#2da6b0"
      });
    } else {
      toRender = React.createElement(CommonEvent, {
        key: event.node.frontmatter.start_time,
        event: event.node,
        color: "#000000"
      });
    }

    return toRender;
  })))));
};

export default DailySchedule;