import "core-js/modules/es6.function.name";
import React from "react";
import SpeakerNameStyles from "../../styles/talk/speaker_name.module.css";
import Occupations from "./occupations";

var SpeakerName = function SpeakerName(_ref) {
  var name = _ref.name,
      occupations = _ref.occupations;
  return React.createElement("div", {
    className: SpeakerNameStyles.container
  }, React.createElement("div", {
    className: SpeakerNameStyles.name_container
  }, React.createElement("div", {
    className: SpeakerNameStyles.name
  }, name), React.createElement("hr", null)), React.createElement(Occupations, {
    occupations: occupations
  }));
};

export default SpeakerName;