import "core-js/modules/es6.function.name";
import "core-js/modules/es7.array.includes";
import "core-js/modules/es6.string.includes";
import staticQueryData from "../../../public/static/d/937816237.json";
import React from "react";
import Img from "gatsby-image";
import { Container, Row, Col } from "reactstrap";
import CompaniesStyles from "../../styles/session/companies.module.css";

var Companies = function Companies(_ref) {
  var companies = _ref.companies;
  var data = staticQueryData.data;
  var companiesData = data.allMarkdownRemark.edges.filter(function (element) {
    return companies.includes(element.node.frontmatter.name);
  });
  return React.createElement("div", {
    className: CompaniesStyles.companiesContainer
  }, React.createElement(Container, null, React.createElement(Row, {
    className: CompaniesStyles.companiesRow
  }, companiesData.map(function (company) {
    return React.createElement(Col, {
      xs: "12",
      md: "3",
      key: company.node.frontmatter.name,
      className: CompaniesStyles.companyLogo
    }, React.createElement(Img, {
      fluid: company.node.frontmatter.img.childImageSharp.fluid
    }));
  }))));
};

export default Companies;