import "core-js/modules/es6.string.link";
import React from "react";
import { Container, Col, Row } from "reactstrap";
import { FaEnvelope, FaLinkedin, FaFacebookSquare, FaMapMarkerAlt } from "react-icons/fa";
import ContactStyles from "../../../styles/mainpage/contacts.module.css";
var links = [{
  icon: React.createElement(FaEnvelope, null),
  href: "mailto:geral@sinf.pt",
  text: "geral@sinf.pt"
}, {
  icon: React.createElement(FaLinkedin, null),
  href: "https://www.linkedin.com/company/sinffeup/",
  text: "sinffeup"
}, {
  icon: React.createElement(FaFacebookSquare, null),
  href: "https://facebook.com/sinfFEUP",
  text: "sinfFEUP"
}];
var location = "https://www.google.com/maps/place/FEUP+-+Faculdade+de+Engenharia+da+Universidade+do+Porto/@41.17794,-8.597688,15z/data=!4m5!3m4!1s0x0:0x8b1e4a0bcdacc840!8m2!3d41.1779401!4d-8.5976876?hl=en-US";

var Contacts = function Contacts() {
  return React.createElement("div", null, React.createElement(Col, {
    className: ContactStyles.title_container
  }, React.createElement("h4", {
    className: ContactStyles.title
  }, "Contacta-nos:"), React.createElement("hr", {
    className: ContactStyles.title_bar
  })), React.createElement(Container, null, React.createElement(Row, {
    className: ContactStyles.info_row
  }, React.createElement(Col, {
    className: ContactStyles.info_col
  }, links.map(function (link, index) {
    return React.createElement(Col, {
      key: index
    }, React.createElement(Row, null, React.createElement("a", {
      href: link.href,
      className: ContactStyles.link
    }, React.createElement("span", {
      className: ContactStyles.icon
    }, link.icon), link.text)));
  })), React.createElement(Col, {
    className: ContactStyles.info_col + " " + ContactStyles.location_col
  }, React.createElement("a", {
    href: location,
    className: ContactStyles.location_link
  }, React.createElement("span", {
    className: ContactStyles.icon
  }, React.createElement(FaMapMarkerAlt, null)), "FEUP ", React.createElement("br", null), "R. Dr. Roberto Frias", React.createElement("br", null), "4200-465 Porto", React.createElement("br", null), "Portugal")))));
};

export default Contacts;