import React from "react";
import { Col } from "reactstrap";
import CompetitionStyles from "../../styles/competition/competition.module.css";

var Prize = function Prize(_ref) {
  var type = _ref.type,
      description = _ref.description,
      title = _ref.title;
  var prizeName;
  if (type === "first") prizeName = "1º prémio";else if (type === "second") prizeName = "2º prémio";else prizeName = "3º prémio";
  return React.createElement(Col, {
    md: "3",
    xs: "12"
  }, React.createElement("div", {
    className: "pl-0"
  }, React.createElement("img", {
    className: CompetitionStyles.squarePrize,
    src: require("../../images/competition/" + type + ".png"),
    height: "280px",
    width: "280px",
    alt: "Prize"
  })), React.createElement("div", {
    className: CompetitionStyles.prizeType
  }, prizeName), React.createElement("div", {
    className: CompetitionStyles.prizeTitle
  }, title), React.createElement("div", {
    className: CompetitionStyles.prizeDescription
  }, description));
};

export default Prize;