/**
 * Layout component that queries for data
 * with Gatsby's useStaticQuery component
 *
 * See: https://www.gatsbyjs.org/docs/use-static-query/
 */
import React from "react";
import PropTypes from "prop-types";
import Navbar from "./navbar";
import Footer from "./footer";
import Utils from "../../styles/utils/utils.module.css";

var Layout = function Layout(_ref) {
  var children = _ref.children;
  return React.createElement(React.Fragment, null, React.createElement("div", {
    className: Utils.sticky
  }, React.createElement(Navbar, {
    logo: true
  })), React.createElement("main", null, children), React.createElement(Footer, null));
};

Layout.propTypes = {
  children: PropTypes.node.isRequired
};
export default Layout;