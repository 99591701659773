import React from "react";
import TimePlace from "./TimePlace";
import icons from "../utils/icons";
import eventsStyles from "../../styles/program/events.module.css";

var SimpleEvent = function SimpleEvent(_ref) {
  var _ref$event = _ref.event,
      title = _ref$event.title,
      start_time = _ref$event.start_time,
      end_time = _ref$event.end_time,
      place = _ref$event.place,
      icon = _ref$event.icon;
  var className = icon ? [eventsStyles.main, eventsStyles.simpleEvent, eventsStyles.withIcon].join(" ") : [eventsStyles.main, eventsStyles.simpleEvent].join(" ");
  return React.createElement("div", {
    "data-date": start_time,
    className: className
  }, icon && icons[icon], React.createElement("div", {
    className: eventsStyles.info
  }, React.createElement("h4", null, title), React.createElement(TimePlace, {
    start_time: start_time,
    end_time: end_time,
    place: place
  })));
};

export default SimpleEvent;