import React from "react";
import { Nav, NavItem, NavLink } from "reactstrap";
import DayNavItem from "./DayNavItem";
import programStyles from "../../styles/program/program.module.css";

var ProgramNav = function ProgramNav(_ref) {
  var days = _ref.days,
      activeTab = _ref.activeTab,
      toggle = _ref.toggle;
  return React.createElement("div", {
    className: programStyles.programNavWrapper
  }, React.createElement("div", {
    className: programStyles.programNavContainer
  }, React.createElement(Nav, {
    className: programStyles.programNav
  }, days.map(function (day, i) {
    return React.createElement(NavItem, {
      key: day.node.id,
      className: activeTab.date === day.node.date ? [programStyles.navItemWrapper, programStyles.active].join(" ") : programStyles.navItemWrapper
    }, React.createElement(NavLink, {
      onClick: function onClick() {
        return toggle(day.node);
      }
    }, React.createElement(DayNavItem, {
      date: day.node.date,
      icon: day.node.icon,
      day: i + 1,
      active: activeTab.date === day.node.date
    })));
  })), React.createElement("div", {
    className: programStyles.themeHeader
  }, React.createElement("h3", {
    className: programStyles.themeHeading
  }, React.createElement("span", {
    className: programStyles.theme
  }, "Tema:"), " ", activeTab.theme))));
};

export default ProgramNav;