import React from "react";
import sponsorsStyles from "../../../styles/mainpage/sponsors.module.css";
import SponsorSection, { getSponsors } from "../sponsors/sponsor-section";
import { useSponsors } from "../../hooks/sponsors-query";

var Sponsors = function Sponsors() {
  var data = useSponsors();
  var mainSponsors = getSponsors(data, "main");
  var goldSponsors = getSponsors(data, "gold");
  var silverSponsors = getSponsors(data, "silver");
  var bronzeSponsors = getSponsors(data, "bronze");
  return (mainSponsors.length > 0 || goldSponsors.length > 0 || silverSponsors.length > 0 || bronzeSponsors.length > 0) && React.createElement("section", {
    id: "sponsors",
    className: sponsorsStyles.sponsorsSection
  }, React.createElement("h2", {
    className: sponsorsStyles.h2
  }, "Sponsors", React.createElement("hr", {
    className: sponsorsStyles.headingLine
  })), mainSponsors.length > 0 && React.createElement("div", {
    className: sponsorsStyles.mainSponsor
  }, React.createElement("div", {
    className: sponsorsStyles.sponsorsType
  }, "Main"), React.createElement("div", {
    className: sponsorsStyles.mainWrapper
  }, mainSponsors)), React.createElement(SponsorSection, {
    sponsorData: goldSponsors,
    type: "gold"
  }), React.createElement(SponsorSection, {
    sponsorData: silverSponsors,
    type: "silver"
  }), React.createElement(SponsorSection, {
    sponsorData: bronzeSponsors,
    type: "bronze"
  }));
};

export default Sponsors;