import React from "react";
import BasicInfo from "./basic-info";
import BannerStyles from "../../../styles/mainpage/banner.module.css";
import Icon from "../../../images/svg/logo_sinf_comp.inline.svg";

var Banner = function Banner() {
  return React.createElement("div", {
    className: BannerStyles.banner
  }, React.createElement(Icon, {
    className: BannerStyles.icon
  }), React.createElement("div", {
    className: BannerStyles.basic_info
  }, React.createElement(BasicInfo, null)));
};

export default Banner;