import React from "react";
import { IconContext } from "react-icons";
import icons from "../utils/icons";
import programStyles from "../../styles/program/program.module.css";

var DayNavItem = function DayNavItem(_ref) {
  var icon = _ref.icon,
      date = _ref.date,
      day = _ref.day,
      active = _ref.active;
  return React.createElement("div", {
    className: programStyles.navItem
  }, React.createElement(IconContext.Provider, {
    value: {
      color: active ? "#FFFFFF" : "#9BD8DC",
      size: "2em"
    }
  }, React.createElement("div", null, icons[icon])), React.createElement("div", {
    className: programStyles.navItemText
  }, React.createElement("h3", null, day, "\xBA dia"), React.createElement("span", null, date)));
};

export default DayNavItem;