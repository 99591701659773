import React from "react";
import SEO from "../components/common/seo";
import Layout from "../components/common/layout";
import Competition from "../components/competition/competition";
import PageBanner from "../components/utils/page_banner";
import Icon from "../images/svg/competicao.inline.svg";

var CompetitionPage = function CompetitionPage() {
  return React.createElement(Layout, null, React.createElement(SEO, {
    title: "Competi\xE7\xE3o de programa\xE7\xE3o"
  }), React.createElement(PageBanner, null, React.createElement(Icon, null)), React.createElement(Competition, null));
};

export default CompetitionPage;