import React from "react";
import { FaCalendarCheck, FaCoffee, FaRobot, FaShieldAlt, FaUserTie } from "react-icons/fa";
var icons = {
  "calendar-check": React.createElement(FaCalendarCheck, {
    color: "#0c0044",
    size: "2em"
  }),
  coffee: React.createElement(FaCoffee, {
    color: "#0c0044",
    size: "2.5em"
  }),
  robot: React.createElement(FaRobot, null),
  "shield-alt": React.createElement(FaShieldAlt, null),
  "user-tie": React.createElement(FaUserTie, null)
};
export default icons;