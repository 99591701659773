import React from "react";
import "bootstrap/dist/css/bootstrap.min.css";
import SEO from "../components/common/seo";
import Navbar from "../components/common/navbar";
import Layout from "../components/common/layout";
import Banner from "../components/mainpage/banner/banner";
import About from "../components/mainpage/about/about";
import Speakers from "../components/mainpage/speakers/speakers";
import Tickets from "../components/mainpage/tickets";
import Sponsors from "../components/mainpage/sponsors/sponsors";
import Contacts from "../components/mainpage/contacts/wrapper";
import MainpageStyles from "../styles/mainpage/mainpage.module.css";

var IndexPage = function IndexPage() {
  return React.createElement("div", null, React.createElement(SEO, {
    title: "Home"
  }), React.createElement("div", {
    className: MainpageStyles.top_bar
  }, React.createElement(Navbar, {
    transparent: true
  })), React.createElement(Banner, null), React.createElement(Layout, null, React.createElement(About, null), React.createElement(Speakers, null), React.createElement(Tickets, null), React.createElement(Sponsors, null), React.createElement(Contacts, null)));
};

export default IndexPage;