import React from "react";
import BannerStyles from "../../styles/utils/page_banner.module.css";

var PageBanner = function PageBanner(_ref) {
  var children = _ref.children;
  return React.createElement("div", {
    className: BannerStyles.container
  }, children);
};

export default PageBanner;