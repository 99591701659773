import "core-js/modules/es6.object.assign";
import React from "react";
import { Container } from "reactstrap";
import Layout from "../components/common/layout";
import SEO from "../components/common/seo";
import Speaker from "../components/talk/speaker";
import Description from "../components/talk/description";
import Participate from "../components/utils/participate";
import Partnership from "../components/talk/partnership";
import TalkStyles from "../styles/talk/talk.module.css";
export default function Template(_ref) {
  var data = _ref.data;
  var talk = data.markdownRemark;
  var info = Object.assign({}, talk.frontmatter);
  return React.createElement(Layout, null, React.createElement(SEO, {
    title: info.title
  }), React.createElement(Container, {
    fluid: true,
    className: TalkStyles.container
  }, React.createElement(Speaker, {
    data: info.speakers[0]
  }), info.partnership ? React.createElement(Partnership, {
    partnership: info.partnership[0]
  }) : "", React.createElement(Description, {
    data: info
  }, React.createElement("div", {
    dangerouslySetInnerHTML: {
      __html: talk.html
    }
  }))), info.registration ? React.createElement(Participate, {
    link: info.registration
  }) : "");
}
export var talkQuery = "2553738734";