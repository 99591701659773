import React from "react";
import { Link } from "gatsby";
import SEO from "../components/common/seo";
import Face from "../images/svg/not_found_face.inline.svg";
import Numbers from "../images/svg/not_found_numbers.inline.svg";
import NotFoundStyles from "../styles/404.module.css";

var NotFoundPage = function NotFoundPage() {
  return React.createElement(React.Fragment, null, React.createElement(SEO, {
    title: "404: Not found"
  }), React.createElement("div", {
    className: NotFoundStyles.main
  }, React.createElement(Face, {
    className: NotFoundStyles.face
  }), React.createElement(Numbers, {
    className: NotFoundStyles.numbers
  }), React.createElement("p", {
    className: NotFoundStyles.text
  }, "Esta p\xE1gina n\xE3o foi encontrada."), React.createElement(Link, {
    to: "/",
    className: NotFoundStyles.btn
  }, "Voltar")));
};

export default NotFoundPage;