import "core-js/modules/es6.function.name";
import React from "react";
import { Link } from "gatsby";
import eventsStyles from "../../styles/program/events.module.css";

var Speakers = function Speakers(_ref) {
  var speakers = _ref.speakers,
      path = _ref.path;

  var printOccupations = function printOccupations(speakers) {
    var occupations = speakers.map(function (_ref2) {
      var occupations = _ref2.occupations;
      return occupations[0].what + " @ " + occupations[0].where;
    });
    occupations = occupations.filter(function (occupation, index, self) {
      return self.indexOf(occupation) === index;
    });
    return occupations.map(function (occupation, index) {
      return index > 0 ? ", " + occupation : occupation;
    });
  };

  return React.createElement(React.Fragment, null, React.createElement("h3", {
    className: eventsStyles.speakers
  }, React.createElement(Link, {
    to: path
  }, speakers.map(function (speaker, i) {
    return i > 0 ? ", " + speaker.name : speaker.name;
  }))), React.createElement("h5", {
    className: eventsStyles.occupations
  }, printOccupations(speakers)));
};

export default Speakers;