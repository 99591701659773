import "core-js/modules/es6.function.name";
import React from "react";
import { Row, Col } from "reactstrap";
import Member from "./member";
import TeamStyles from "../../styles/team/team.module.css";
import ParticipantsStyle from "../../styles/utils/participants-display.module.css";

var divide_rows = function divide_rows(members, n_per_row) {
  var member_rows = [];
  var tmp = [];

  for (var i = 0; i < members.length; i++) {
    var element = members[i];

    if (i % n_per_row === 0 && tmp.length !== 0) {
      member_rows.push(tmp);
      tmp = [];
    }

    tmp.push(element);
  }

  if (tmp.length !== 0) member_rows.push(tmp);
  return member_rows;
};

var Team = function Team(_ref) {
  var name = _ref.name,
      members = _ref.members,
      n_per_row = _ref.n_per_row,
      start_odd = _ref.start_odd;
  var member_rows = divide_rows(members, n_per_row);
  var odd = start_odd;
  return React.createElement(Row, {
    className: TeamStyles.team_container
  }, React.createElement(Col, {
    className: TeamStyles.name_container
  }, React.createElement("h2", null, name)), React.createElement(Col, {
    className: TeamStyles.members_container
  }, member_rows.map(function (row_members, row_index) {
    odd ^= true;
    return React.createElement(Row, {
      className: ParticipantsStyle.member_row,
      key: "row" + row_index
    }, row_members.map(function (value, index) {
      var color = !odd ? n_per_row - index - 1 : index;
      return React.createElement(Col, {
        key: "member" + index,
        className: ParticipantsStyle.member_container
      }, React.createElement(Member, {
        data: value,
        color: color
      }));
    }));
  })));
};

export default Team;