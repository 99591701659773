import React from "react";
import { Link } from "gatsby";
import Layout from "../components/common/layout";
import SEO from "../components/common/seo";
import Sponsors from "../components/mainpage/sponsors/sponsors";

var SponsorsPage = function SponsorsPage() {
  return React.createElement(Layout, null, React.createElement(SEO, {
    title: "Patroc\xEDnios"
  }), React.createElement(Sponsors, null), React.createElement(Link, {
    to: "/"
  }, "Go back to the homepage"));
};

export default SponsorsPage;