import "core-js/modules/es6.regexp.match";
import "core-js/modules/es7.array.includes";
import "core-js/modules/es6.string.includes";
import React, { useState } from "react";
import { Link } from "gatsby";
import { Collapse } from "reactstrap";
import TimePlace from "./TimePlace";
import Speakers from "./Speakers";
import DescriptionToggler from "./DescriptionToggler";
import eventsStyles from "../../styles/program/events.module.css";
import SimpleEvent from "./SimpleEvent";

var CommonEvent = function CommonEvent(_ref) {
  var color = _ref.color,
      event = _ref.event;
  var html = event.html,
      _event$frontmatter = event.frontmatter,
      title = _event$frontmatter.title,
      path = _event$frontmatter.path,
      speakers = _event$frontmatter.speakers,
      start_time = _event$frontmatter.start_time,
      end_time = _event$frontmatter.end_time,
      place = _event$frontmatter.place,
      type = _event$frontmatter.type;

  var _useState = useState(false),
      showAll = _useState[0],
      setShowAll = _useState[1];

  return type === "Placeholder" ? React.createElement(SimpleEvent, {
    event: event.frontmatter
  }) : React.createElement("div", {
    "data-date": start_time,
    className: [eventsStyles.commonEvent, eventsStyles.main].join(" ")
  }, React.createElement(DescriptionToggler, {
    id: "toggleShowAll-" + title,
    showAll: showAll,
    setShowAll: setShowAll,
    backgroundColor: color
  }), React.createElement("div", null, React.createElement("h3", {
    className: eventsStyles.title
  }, React.createElement(Link, {
    style: {
      color: color
    },
    to: path
  }, path.includes("workshops") && "Workshop: ", " ", React.createElement("span", {
    className: !path.match(/sessions|visits/) ? eventsStyles.eventTitle : undefined
  }, title))), speakers && React.createElement(Speakers, {
    speakers: speakers,
    path: path
  }), React.createElement(TimePlace, {
    start_time: start_time,
    end_time: end_time,
    place: place
  }), React.createElement(Collapse, {
    isOpen: showAll
  }, React.createElement("div", {
    className: eventsStyles.description,
    dangerouslySetInnerHTML: {
      __html: html
    }
  }))));
};

export default CommonEvent;