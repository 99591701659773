import "core-js/modules/es6.string.link";
import React from "react";
import Img from "gatsby-image";
import { FaLinkedin, FaGithubSquare, FaTwitterSquare } from "react-icons/fa";
import Globe from "../../images/svg/web_icon.inline.svg";
import FrameStyles from "../../styles/talk/frame.module.css";

var SpeakerFrame = function SpeakerFrame(_ref) {
  var img = _ref.img,
      website = _ref.website,
      linkedin = _ref.linkedin,
      github = _ref.github,
      twitter = _ref.twitter;
  return React.createElement("div", null, React.createElement(Img, {
    className: FrameStyles.image,
    fluid: img.childImageSharp.fluid
  }), React.createElement("div", {
    className: FrameStyles.link_container
  }, twitter ? React.createElement("a", {
    href: twitter,
    className: FrameStyles.link
  }, React.createElement(FaTwitterSquare, null)) : "", " ", linkedin ? React.createElement("a", {
    href: linkedin,
    className: FrameStyles.link
  }, React.createElement(FaLinkedin, null)) : "", github ? React.createElement("a", {
    href: github,
    className: FrameStyles.link
  }, React.createElement(FaGithubSquare, null)) : "", website ? React.createElement("a", {
    href: website,
    className: FrameStyles.link
  }, React.createElement(Globe, null)) : ""));
};

export default SpeakerFrame;