import React from "react";
import { Container, Row, Col } from "reactstrap";
import BannerStyles from "../../styles/session/banner.module.css";

var Banner = function Banner(_ref) {
  var title = _ref.title;
  return React.createElement("div", {
    className: BannerStyles.bannerContainer
  }, React.createElement(Container, null, React.createElement(Row, null, React.createElement(Col, {
    xs: "12",
    md: {
      size: 8,
      offset: 4
    },
    className: BannerStyles.titleContainer
  }, React.createElement("h1", {
    className: BannerStyles.title
  }, title, React.createElement("hr", {
    className: BannerStyles.underline
  }))))));
};

export default Banner;