import "core-js/modules/es6.string.link";
import React from "react";
import { Container, Row, Col, Button } from "reactstrap";
import ParticipateStyles from "../../styles/utils/participate.module.css";

var Participate = function Participate(_ref) {
  var link = _ref.link;
  if (!link) return null;
  return React.createElement("div", {
    className: ParticipateStyles.participateSection + " container-fluid"
  }, React.createElement(Container, null, React.createElement(Row, null, React.createElement(Col, {
    xs: "12",
    className: ParticipateStyles.colContent
  }, React.createElement("div", {
    className: ParticipateStyles.participateText
  }, "N\xE3o percas mais tempo!"), React.createElement(Button, {
    href: link,
    outline: true,
    className: ParticipateStyles.participateButton + " mt-4"
  }, "Participar")))));
};

export default Participate;