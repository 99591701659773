// prefer default export if available
var preferDefault = function preferDefault(m) {
  return m && m.default || m;
};

exports.components = {
  "component---src-templates-session-js": function componentSrcTemplatesSessionJs() {
    return import("/opt/build/repo/src/templates/session.js"
    /* webpackChunkName: "component---src-templates-session-js" */
    );
  },
  "component---src-templates-talk-js": function componentSrcTemplatesTalkJs() {
    return import("/opt/build/repo/src/templates/talk.js"
    /* webpackChunkName: "component---src-templates-talk-js" */
    );
  },
  "component---src-pages-404-js": function componentSrcPages404Js() {
    return import("/opt/build/repo/src/pages/404.js"
    /* webpackChunkName: "component---src-pages-404-js" */
    );
  },
  "component---src-pages-coming-js": function componentSrcPagesComingJs() {
    return import("/opt/build/repo/src/pages/coming.js"
    /* webpackChunkName: "component---src-pages-coming-js" */
    );
  },
  "component---src-pages-competicao-programacao-js": function componentSrcPagesCompeticaoProgramacaoJs() {
    return import("/opt/build/repo/src/pages/competicao-programacao.js"
    /* webpackChunkName: "component---src-pages-competicao-programacao-js" */
    );
  },
  "component---src-pages-equipa-js": function componentSrcPagesEquipaJs() {
    return import("/opt/build/repo/src/pages/equipa.js"
    /* webpackChunkName: "component---src-pages-equipa-js" */
    );
  },
  "component---src-pages-index-js": function componentSrcPagesIndexJs() {
    return import("/opt/build/repo/src/pages/index.js"
    /* webpackChunkName: "component---src-pages-index-js" */
    );
  },
  "component---src-pages-patrocinios-js": function componentSrcPagesPatrociniosJs() {
    return import("/opt/build/repo/src/pages/patrocinios.js"
    /* webpackChunkName: "component---src-pages-patrocinios-js" */
    );
  },
  "component---src-pages-program-js": function componentSrcPagesProgramJs() {
    return import("/opt/build/repo/src/pages/program.js"
    /* webpackChunkName: "component---src-pages-program-js" */
    );
  },
  "component---src-pages-speakers-js": function componentSrcPagesSpeakersJs() {
    return import("/opt/build/repo/src/pages/speakers.js"
    /* webpackChunkName: "component---src-pages-speakers-js" */
    );
  }
};