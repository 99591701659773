import React from "react";
import Img from "gatsby-image";
import sponsorStyles from "../../../styles/mainpage/single-sponsor.module.css";

var SingleSponsor = function SingleSponsor(_ref) {
  var sponsor = _ref.sponsor,
      type = _ref.type;
  return React.createElement("div", {
    className: sponsorStyles.sponsorContainer
  }, React.createElement(Img, {
    className: [sponsorStyles.image, sponsorStyles[type]].join(" "),
    fluid: sponsor.img.childImageSharp.fluid
  }));
};

export default SingleSponsor;