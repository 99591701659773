import React from "react";
import { Nav, NavItem, NavLink } from "reactstrap";
import programStyles from "../../styles/program/program.module.css";

var EventsNav = function EventsNav(_ref) {
  var toggle = _ref.toggle,
      activeTab = _ref.activeTab;
  return React.createElement(Nav, {
    className: "justify-content-center"
  }, React.createElement(NavItem, null, React.createElement(NavLink, {
    onClick: function onClick() {
      return toggle("talks");
    },
    className: activeTab === "talks" ? [programStyles.innerTab, programStyles.active].join(" ") : programStyles.innerTab
  }, "Palestras", activeTab === "talks" && React.createElement("hr", {
    className: programStyles.headingLine
  }))), React.createElement(NavItem, null, React.createElement(NavLink, {
    onClick: function onClick() {
      return toggle("activities");
    },
    className: activeTab === "activities" ? [programStyles.innerTab, programStyles.active].join(" ") : programStyles.innerTab
  }, "Atividades", activeTab === "activities" && React.createElement("hr", {
    className: programStyles.headingLine
  }))));
};

export default EventsNav;