import "core-js/modules/es6.function.name";
import React from "react";
import { Container, Col, Row } from "reactstrap";
import SpeakerFrame from "./speaker_frame";
import SpeakerName from "./speaker_name";
import SpeakerStyles from "../../styles/talk/speaker.module.css";

var Speaker = function Speaker(_ref) {
  var data = _ref.data;
  return React.createElement(Container, {
    className: SpeakerStyles.container,
    fluid: true
  }, React.createElement("div", {
    className: SpeakerStyles.id_container
  }, React.createElement(Row, {
    className: SpeakerStyles.id_wrapper
  }, React.createElement(Col, {
    className: SpeakerStyles.frame
  }, React.createElement(SpeakerFrame, data)), React.createElement(Col, {
    className: SpeakerStyles.name
  }, React.createElement(SpeakerName, data), React.createElement("p", {
    className: SpeakerStyles.bio
  }, data.bio)))), React.createElement("div", {
    className: SpeakerStyles.mb_id_container
  }, React.createElement("div", {
    className: SpeakerStyles.mb_blue
  }, React.createElement(SpeakerName, data), React.createElement(SpeakerFrame, data)), React.createElement("p", {
    className: SpeakerStyles.bio
  }, data.bio)));
};

export default Speaker;