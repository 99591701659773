import "core-js/modules/es6.string.link";
import React from "react";
import { Link } from "gatsby";
import SoonStyles from "../../styles/soon.module.css";
import Icon from "../../images/svg/gradient-outline.inline.svg";

var Brevemente = function Brevemente() {
  return React.createElement("div", {
    className: SoonStyles.banner
  }, React.createElement(Icon, {
    className: SoonStyles.banner_icon
  }), React.createElement("h1", {
    className: SoonStyles.title
  }, "P\xE1gina em constru\xE7\xE3o"), React.createElement(Link, {
    className: SoonStyles.link,
    to: "/"
  }, "Voltar"));
};

export default Brevemente;