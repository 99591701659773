import React from "react";
import { Row, Col } from "reactstrap";
import SingleSpeaker from "./single-speaker";
import ParticipantsStyle from "../../styles/utils/participants-display.module.css";
import SpeakersStyles from "../../styles/speakers/speakers.module.css";

var getSpeakers = function getSpeakers(talks) {
  var speakers = [];
  talks.filter(function (_ref) {
    var node = _ref.node;
    return node.frontmatter.type !== "Placeholder";
  }).forEach(function (_ref2) {
    var node = _ref2.node;
    node.frontmatter.speakers.forEach(function (speaker) {
      speaker.path = node.frontmatter.path;
      speakers.push(speaker);
    });
  });
  return speakers;
};

var DailySpeakers = function DailySpeakers(_ref3) {
  var talks = _ref3.talks,
      day = _ref3.day;
  var speakers = getSpeakers(talks);
  var date = new Date(talks[0].node.frontmatter.day);
  return React.createElement(Row, {
    className: "mt-5"
  }, React.createElement(Col, {
    xl: {
      size: "2",
      offset: "0"
    },
    lg: {
      size: "3",
      offset: "1"
    },
    md: {
      size: "3",
      offset: "0"
    },
    sm: "4",
    xs: "12",
    className: SpeakersStyles.dayDescription + " mb-3"
  }, React.createElement("div", {
    className: SpeakersStyles.dayNumber
  }, "Dia ", day), React.createElement("div", {
    className: SpeakersStyles.date
  }, date.getDate() + " Outubro")), React.createElement(Col, {
    xl: "10",
    lg: "6",
    md: "8",
    sm: "8",
    xs: "12"
  }, React.createElement(Row, {
    className: ParticipantsStyle.member_row
  }, speakers.map(function (speaker, index) {
    return index % 8 < 4 ? React.createElement(SingleSpeaker, {
      key: index,
      speaker: speaker,
      color: index % 4
    }) : React.createElement(SingleSpeaker, {
      key: index,
      speaker: speaker,
      color: 3 - index % 4
    });
  }))));
};

export default DailySpeakers;