import React from "react";
import { Container, Col, Row } from "reactstrap";
import Contacts from "./contacts";
import Location from "./location";
import ContactStyles from "../../../styles/mainpage/contacts.module.css";

var ContactWrapper = function ContactWrapper() {
  return React.createElement(Container, {
    id: "contactos",
    className: ContactStyles.wrapper,
    fluid: true
  }, React.createElement(Row, {
    className: ContactStyles.container + " " + ContactStyles.main_container
  }, React.createElement(Col, {
    className: ContactStyles.container
  }, React.createElement(Row, {
    className: ContactStyles.container + " " + ContactStyles.info_container
  }, React.createElement(Contacts, null))), React.createElement(Col, {
    className: ContactStyles.map_container
  }, React.createElement(Row, {
    className: ContactStyles.map_container
  }, React.createElement(Location, null)))));
};

export default ContactWrapper;