import React from "react";
import { FaLinkedin, FaFacebookSquare, FaEnvelope } from "react-icons/fa";
import BannerStyles from "../../../styles/mainpage/banner.module.css";
var links = [{
  icon: React.createElement(FaLinkedin, null),
  href: "https://www.linkedin.com/company/sinffeup/"
}, {
  icon: React.createElement(FaFacebookSquare, null),
  href: "https://facebook.com/sinfFEUP"
}, {
  icon: React.createElement(FaEnvelope, null),
  href: "mailto:geral@sinf.pt"
}];

var BasicInfo = function BasicInfo() {
  return React.createElement("div", {
    className: BannerStyles.info
  }, React.createElement("p", null, "2\xAA EDI\xC7\xC3O"), React.createElement("p", null, "28 A 30 OUTUBRO 2019 | FEUP"), React.createElement("div", {
    className: BannerStyles.icon_links
  }, links.map(function (_ref, index) {
    var icon = _ref.icon,
        href = _ref.href;
    return React.createElement("a", {
      href: href,
      className: BannerStyles.icon_link,
      key: index
    }, icon);
  })));
};

export default BasicInfo;