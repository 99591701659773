import "core-js/modules/es6.function.name";
import React from "react";
import SingleSponsor from "./single-sponsor";
import sponsorsStyles from "../../../styles/mainpage/sponsors.module.css";
import Carousel from "../../utils/carousel";
export var getSponsors = function getSponsors(data, type) {
  return data.allMarkdownRemark.edges.filter(function (_ref) {
    var node = _ref.node;
    return node.frontmatter.type === type;
  }).map(function (_ref2) {
    var node = _ref2.node;
    return React.createElement(SingleSponsor, {
      key: node.frontmatter.name,
      sponsor: node.frontmatter,
      type: type
    });
  });
};

var SponsorSection = function SponsorSection(_ref3) {
  var sponsorData = _ref3.sponsorData,
      type = _ref3.type;
  var wrapperType, sponsorText, sponsorType, numDesktop;

  if (type === "gold") {
    wrapperType = sponsorsStyles.goldWrapper;
    sponsorText = sponsorsStyles.goldSponsor;
    sponsorType = "Gold";
    numDesktop = sponsorData.length < 2 ? sponsorData.length : 2;
  } else if (type === "silver") {
    wrapperType = sponsorsStyles.silverWrapper;
    sponsorText = sponsorsStyles.silverSponsor;
    sponsorType = "Silver";
    numDesktop = sponsorData.length < 3 ? sponsorData.length : 3;
  } else {
    wrapperType = sponsorsStyles.bronzeWrapper;
    sponsorText = sponsorsStyles.bronzeSponsor;
    sponsorType = "Bronze";
    numDesktop = sponsorData.length < 5 ? sponsorData.length : 5;
  }

  return numDesktop > 0 && React.createElement("div", {
    className: [sponsorsStyles.otherSponsors, wrapperType].join(" ")
  }, React.createElement("div", {
    className: [sponsorsStyles.sponsorsType, sponsorText].join(" ")
  }, sponsorType), React.createElement(Carousel, {
    numMobileItems: 1,
    numTabletItems: 1,
    numDesktopItems: numDesktop,
    auto: true
  }, sponsorData));
};

export default SponsorSection;