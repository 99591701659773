import "core-js/modules/es6.regexp.replace";
import React from "react";
import { Container, Row, Col } from "reactstrap";
import TimePlace from "./time_place";
import DescriptionStyles from "../../styles/talk/description.module.css";

var calculateDay = function calculateDay(date) {
  var oneDay = 24 * 60 * 60 * 1000;
  var first_day = new Date(2019, 10, 28);
  var day = new Date(2019, 10, date.replace(/(^\d+)(.+$)/i, "$1"));
  return Math.round(Math.abs((day - first_day.getTime()) / oneDay)) + 1;
};

var Description = function Description(_ref) {
  var data = _ref.data,
      children = _ref.children;
  return React.createElement(Row, {
    className: DescriptionStyles.row
  }, React.createElement(Col, null, React.createElement(Container, {
    className: DescriptionStyles.container
  }, React.createElement("h2", {
    className: DescriptionStyles.title
  }, data.type + ": " + data.title), React.createElement(Row, {
    className: DescriptionStyles.info_container
  }, React.createElement(Col, {
    className: DescriptionStyles.date
  }, React.createElement("div", null, "Dia ", calculateDay(data.day)), React.createElement("div", null, data.day)), React.createElement(Col, null, children, React.createElement(TimePlace, {
    time: data.start_time,
    place: data.place
  }))))));
};

export default Description;