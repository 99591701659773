import "core-js/modules/es6.string.link";
import React from "react";
import Img from "gatsby-image";
import { FaLinkedin, FaTwitter, FaGithubSquare } from "react-icons/fa";
import WebIcon from "../../images/svg/web_icon.inline.svg";
import OverlayStyles from "../../styles/utils/overlay.module.css";
var colors = [OverlayStyles.bg1, OverlayStyles.bg2, OverlayStyles.bg3, OverlayStyles.bg4];

var Overlay = function Overlay(_ref) {
  var image = _ref.image,
      color = _ref.color,
      linkedin = _ref.linkedin,
      twitter = _ref.twitter,
      github = _ref.github,
      website = _ref.website,
      children = _ref.children;
  return React.createElement("div", {
    className: OverlayStyles.container
  }, React.createElement(Img, {
    fluid: image,
    className: OverlayStyles.image
  }), React.createElement("div", {
    className: OverlayStyles.info_overlay + " " + OverlayStyles.overlay + " " + colors[color]
  }, React.createElement("div", {
    className: OverlayStyles.links
  }, linkedin ? React.createElement("a", {
    href: linkedin,
    className: OverlayStyles.link
  }, React.createElement(FaLinkedin, null)) : "", twitter ? React.createElement("a", {
    href: twitter,
    className: OverlayStyles.link
  }, React.createElement(FaTwitter, null)) : "", github ? React.createElement("a", {
    href: github,
    className: OverlayStyles.link
  }, React.createElement(FaGithubSquare, null)) : "", website ? React.createElement("a", {
    href: website,
    className: OverlayStyles.link
  }, React.createElement(WebIcon, {
    className: OverlayStyles.webIcon
  })) : ""), children), React.createElement("div", {
    className: OverlayStyles.color_overlay + " " + OverlayStyles.overlay + " " + colors[color]
  }));
};

export default Overlay;